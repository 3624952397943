(function () {
    $(function () {
        if ($(window).width() < 768) { // do not load for mobile view
            return;
        }
        //var appName = 'home';
        //if (window.ContactUsSliderSchema !== undefined &&
        //    window.ContactUsSliderSchema.AppName !== undefined &&
        //    window.ContactUsSliderSchema.AppName !== '') {
        //    appName = window.ContactUsSliderSchema.AppName;
        //}
        //var baseUrl = 'https://www.dell.com/support/components/contactusslider/getfiles?appName=' + appName;
        //if (window.location.origin.indexOf("wip") !== -1) {
        //    baseUrl = window.location.origin + "/support/components/contactusslider/getfiles?appName=" + appName;
        //}
        //$.ajax({
        //    url: baseUrl,
        //    contentType: 'application/json',
        //    async: true,
        //    cache: false,
        //    success: function(result) {
        //        for (var i = 0, j = result.length; i < j; i++) {
        //            loadContactUsSliderFile(result[i], function() {});
        //        }
        //    },
        //    failure: function(error) {
        //        console.error(error);
        //    },
        //    error: function(error) {
        //        console.error(error);
        //    }
        //});

        // for TTI avoid ajax call
        var files = [
            "https://www.dellcdn.com/support/staticcdn/contactus-slider/common/css/2.3/contactus-slider.min.css",
            "https://www.dellcdn.com/support/staticcdn/contactus-slider/common/js/2.3/contactus-slider.min.js"
        ];
        for (var i = 0, j = files.length; i < j; i++) {
            loadContactUsSliderFile(files[i], function () { });
        }
    });

    function loadContactUsSliderFile(url, callback) {
        var jsExpr = new RegExp("js$", "i");
        var cssExpr = new RegExp("css$", "i");
        var jsonExpr = new RegExp("json$", "i");

        var testurl = url.split('?')[0];
        var type = null;
        if (jsExpr.test(testurl) || jsonExpr.test(testurl)) {
            type = 'text/javascript';
        }
        else if (cssExpr.test(testurl)) {
            type = 'text/css';
        }

        var tag = null;
        switch (type) {
            case 'text/javascript':
                tag = document.createElement('script');
                tag.type = type;
                tag.src = url;
                tag.async = true;
                break;
            case 'text/css':
                tag = document.createElement('link');
                tag.rel = 'stylesheet';
                tag.type = type;
                tag.href = url;
                break;
        }
        if (callback != null) {
            if (tag.readyState) { // IE, include IE9
                tag.onreadystatechange = function () {
                    if (tag.readyState == "loaded" || tag.readyState == "complete") {
                        tag.onreadystatechange = null;
                        callback();
                    }
                };
            } else {
                tag.onload = function () { // Other browsers
                    callback();
                };
            }
        }
        var a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(tag, a);
    }
})();